export default {
    isMenu: false,
    proindex: 0,
    fixed: false,
    showimg: true,
    prolist: [{
        title: "优越服务",
        btitle: "一站式金融增值服务平台",
        text: "优越积分整合海量品牌，提供没事、娱乐、出行、阅读、生活服务、充值缴费等上千种权益产品，支持企业灵活搭配自有商品及创建优惠券，吸引用户积极参与活动，发挥积分商城激励作用",
        vxcodeimg: require("../image/lottery.jpg"),
        phoneimg: require("../image/lottery.png"),
        color: "rgba(225, 58, 86)",
        mobileWx: "公众号",
        mobileWy: "网页版",
        mobileSmaller: true
    } ],
    cooperImgList: [{
            cooperimg: require("../image/cooperation7.png"),
        },
        {
            cooperimg: require("../image/cooperation5.png"),
        },
        {
            cooperimg: require("../image/cooperation10.png"),
        },
        {
            cooperimg: require("../image/cooperation6.png"),
        },
        {
            cooperimg: require("../image/cooperation4.png"),
        },
        {
            cooperimg: require("../image/cooperation9.png"),
        },
        {
            cooperimg: require("../image/cooperation1.png"),
        },
        {
            cooperimg: require("../image/cooperation2.png"),
        },
        {
            cooperimg: require("../image/cooperation8.png"),
        },
    ],
    swiperlist: [{
        img: require('../image/home/swiper.png')
    }, {
        img: require('../image/home/swiper2.png')
    }, {
        img: require('../image/home/swiper3.png')
    }, {
        img: require('../image/home/swiper4.png')
    }, ],
    serviceList: [{
        title: "数字营销",
        icon: require("../image/shuziIcon.png"),
        icon2: require("../image/shuziIcon2.png")
    }, {
        title: "积分方案",
        icon: require("../image/jifenIcon.png"),
        icon2: require("../image/jifenIcon2.png")
    }, {
        title: "技术研发",
        icon: require("../image/jishuIcon.png"),
        icon2: require("../image/jishuIcon2.png")
    }, {
        title: "智慧运营",
        icon: require("../image/zhihuiIcon.png"),
        icon2: require("../image/zhihuiIcon2.png")
    }, {
        title: "权益资源",
        icon: require("../image/quanyiIcon.png"),
        icon2: require("../image/quanyiIcon2.png")
    }],
    serviceDetail: [{
        text: "提供H5游戏、码券兑换、微信红包、全媒体互动等营销组合模式，驱动企业实现业绩增长。",
        cell: "全媒体营销",
        cell2: "H5游戏营销",
        cell3: "数据化的精准营销",
        bg: require("../image/shuziBg.png")
    }, {
        text: "深度分析企业积分需求，提供积分商城搭建、积分运营、积分礼品供应、积分兑换、积分营销等服务。",
        cell: "积分商城搭建",
        cell2: "积分代运营",
        cell3: "积分体系定制方案",
        bg: require("../image/jifenBg.png")
    }, {
        text: "90后技术团队，微信公众号、小程序、支付宝、APP等一键部署，为开发者提供强悍活动支撑能力。",
        cell: "微信公众号、小程序",
        cell2: "H5",
        cell3: "APP、网页",
        bg: require("../image/jishuBg.png")
    }, {
        text: "全渠道智能客服，7*24小时及时响应，降低用户投诉率，提高满意度，构建360°视图实现全链路运营。",
        cell: "200+运营团队",
        cell2: "1V1专属服务",
        cell3: "7*24小时及时响应",
        bg: require("../image/zhihuiBg.png")
    }, {
        text: "严苛选品，与优质供应商合作，提供包含美食、饮品、出行、阅读、生活服务等口碑极佳的品牌商品。",
        cell: "官方渠道",
        cell2: "品类繁多",
        cell3: "无忧售后",
        bg: require("../image/quanyiBg.png")
    }],
    brandList: [{
            title: "优越金融",
            text: "为银行、保险、证券等金融机构提供金融营销解决方案，高效对接、优先处理，用户参与度及满意度高，助力数字化转型升级。",
            t1: "创意内容",
            t2: "快速上线",
            t3: "技术支持",
            logo: [
                require("../image/aoshun.png"),
                require("../image/youyue.png"),
                require("../image/yylp.png"),
                require("../image/zhonghua.png"),
            ],
            timg: require("../image/brandIcon.png"),
        },
        {
            title: "优越积分",
            text: "提供一站式积分运营服务，包括积分商城方案策划、积分商城搭建、积分运营、商品供应、售后服务等，帮助企业建立健全积分运营体系。",
            t1: "积分运营",
            t2: "商城搭建",
            t3: "数据分析",
            logo: [
                require("../image/mayi.png"),
                require("../image/lqs.png"),
                require("../image/lingshi.png"),
            ],
            timg: require("../image/brandIcon2.png"),
        },
        {
            title: "优越营销",
            text: "整合H5游戏、码券兑换、微信红包互动、短视频、软文推广等营销形式，企业可根据自身需求进行选择，高效精准触达目标用户。",
            t1: "形式灵活",
            t2: "拉新促活",
            t3: "应用场景广泛",
            logo: [
                require("../image/yueying.png"),
                require("../image/zhonghua.png"),
            ],
            timg: require("../image/brandIcon3.png"),
        },
        {
            title: "优越福利",
            text: "为大中小型企业提供体验感更强、礼遇激励更直接的福利体系，节省成本、管理便捷，进一步拉近企业、员工、客户三者之间的关系。",
            t1: "年节关怀",
            t2: "年节关怀",
            t3: "协同售后",
            logo: [
                require("../image/juan.png"),
                require("../image/renshou.png"),
            ],
            timg: require("../image/brandIcon4.png"),
        },
    ],
    serviceNum: "0"
};